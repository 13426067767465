import React from 'react'

import SocialLinks from '../socialLinks/SocialLinks'

import './footer.scss'

const Footer = ({ socialLinks }) => (
  <footer className="white text-center">
    <SocialLinks socialLinks={socialLinks} />
    <p>©{new Date().getFullYear()} Maximum Overdrive</p>
  </footer>
)

export default Footer
