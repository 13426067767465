import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import "./mobile-nav.scss"

const MobileNav = () => {
  const [isOpen, setIsOpen] = useState(false)
  const drawerRef = useRef(null)
  const triggerRef = useRef(null)
  function close() {
    setIsOpen(false)
  }
  useEffect(() => {
    const main = document.getElementById("main")
    main.classList = isOpen ? "menu-open" : ""
  }, [isOpen])
  useEffect(() => {
    function handleClick(e) {
      if (
        !drawerRef?.current?.contains(e.target) &&
        !triggerRef?.current?.contains(e.target)
      ) {
        setIsOpen(false)
      }
    }

    function handleEsc(e) {
      if (e.keyCode === 27) {
        setIsOpen(false)
      }
    }
    window.addEventListener("keydown", handleEsc)
    window.addEventListener("click", handleClick)
    return () => {
      window.removeEventListener("click", handleClick)
      window.removeEventListener("keydown", handleEsc)
    }
  })

  return (
    <>
      <div className={`mobile-nav ${isOpen ? "active" : ""}`}>
        <button
          className="menu-trigger"
          ref={triggerRef}
          onClick={() => setIsOpen(!isOpen)}
          aria-label="open menu"
        >
          <div className="line" />
          <div className="line" />
          <div className="line" />
        </button>
      </div>
      <div ref={drawerRef} className={`drawer ${isOpen ? "open" : ""}`}>
        <Link to="/#about" onClick={close}>
          About
        </Link>
        <Link to="/#shows" onClick={close}>
          Shows
        </Link>
        <Link to="/#song-list" onClick={close}>
          Song list
        </Link>
        <Link to="/#testimonials" onClick={close}>
          Testimonials
        </Link>
        <Link to="/#contact" onClick={close}>
          Contact
        </Link>
      </div>
    </>
  )
}

export default MobileNav
