import { Link } from "gatsby"
import * as React from "react"

import MobileNav from "./MobileNav"

import "./nav.scss"

const Nav = () => (
  <>
    <div className="nav white">
      <div className="nav-left">
        <Link to="/#about">About</Link>
        <Link to="/#shows">Shows</Link>
        <Link to="/#song-list">Song List</Link>
      </div>
      <div className="tag-line uppercase bold f-heading">
        <p>Join the party.</p>
        <p>Live the 80s.</p>
      </div>
      <div className="nav-right">
        <Link to="/#testimonials">Testimonials</Link>
        <Link to="/#contact">Contact</Link>
      </div>
    </div>
    <MobileNav />
  </>
)

export default Nav
