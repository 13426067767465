import React from 'react'

import './social-links.scss'

const SocialLinks = ({ socialLinks }) => (
  <div className="social-links">
    {socialLinks &&
      socialLinks.map(link => (
        <a
          key={link.url}
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={link.icon} alt={link.name} />
        </a>
      ))}
  </div>
)

export default SocialLinks
